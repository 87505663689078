/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

/** requires(NONE) */
export interface MemoiseDeleteReq {
  key: string;
}

export interface MemoiseDeleteResp {
  success: boolean;
}

/** requires(NONE) */
export interface MemoiseDeleteByRegexReq {
  pattern: string;
}

export interface MemoiseDeleteByRegexResp {
  numDeleted: number;
}

function createBaseMemoiseDeleteReq(): MemoiseDeleteReq {
  return { key: "" };
}

export const MemoiseDeleteReq = {
  encode(message: MemoiseDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseDeleteReq {
    return { key: isSet(object.key) ? String(object.key) : "" };
  },

  toJSON(message: MemoiseDeleteReq): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseDeleteReq>, I>>(base?: I): MemoiseDeleteReq {
    return MemoiseDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseDeleteReq>, I>>(object: I): MemoiseDeleteReq {
    const message = createBaseMemoiseDeleteReq();
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseMemoiseDeleteResp(): MemoiseDeleteResp {
  return { success: false };
}

export const MemoiseDeleteResp = {
  encode(message: MemoiseDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.success === true) {
      writer.uint32(8).bool(message.success);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.success = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseDeleteResp {
    return { success: isSet(object.success) ? Boolean(object.success) : false };
  },

  toJSON(message: MemoiseDeleteResp): unknown {
    const obj: any = {};
    message.success !== undefined && (obj.success = message.success);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseDeleteResp>, I>>(base?: I): MemoiseDeleteResp {
    return MemoiseDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseDeleteResp>, I>>(object: I): MemoiseDeleteResp {
    const message = createBaseMemoiseDeleteResp();
    message.success = object.success ?? false;
    return message;
  },
};

function createBaseMemoiseDeleteByRegexReq(): MemoiseDeleteByRegexReq {
  return { pattern: "" };
}

export const MemoiseDeleteByRegexReq = {
  encode(message: MemoiseDeleteByRegexReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pattern !== "") {
      writer.uint32(10).string(message.pattern);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseDeleteByRegexReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseDeleteByRegexReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pattern = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseDeleteByRegexReq {
    return { pattern: isSet(object.pattern) ? String(object.pattern) : "" };
  },

  toJSON(message: MemoiseDeleteByRegexReq): unknown {
    const obj: any = {};
    message.pattern !== undefined && (obj.pattern = message.pattern);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseDeleteByRegexReq>, I>>(base?: I): MemoiseDeleteByRegexReq {
    return MemoiseDeleteByRegexReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseDeleteByRegexReq>, I>>(object: I): MemoiseDeleteByRegexReq {
    const message = createBaseMemoiseDeleteByRegexReq();
    message.pattern = object.pattern ?? "";
    return message;
  },
};

function createBaseMemoiseDeleteByRegexResp(): MemoiseDeleteByRegexResp {
  return { numDeleted: 0 };
}

export const MemoiseDeleteByRegexResp = {
  encode(message: MemoiseDeleteByRegexResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numDeleted !== 0) {
      writer.uint32(8).uint32(message.numDeleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseDeleteByRegexResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseDeleteByRegexResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numDeleted = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseDeleteByRegexResp {
    return { numDeleted: isSet(object.numDeleted) ? Number(object.numDeleted) : 0 };
  },

  toJSON(message: MemoiseDeleteByRegexResp): unknown {
    const obj: any = {};
    message.numDeleted !== undefined && (obj.numDeleted = Math.round(message.numDeleted));
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseDeleteByRegexResp>, I>>(base?: I): MemoiseDeleteByRegexResp {
    return MemoiseDeleteByRegexResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseDeleteByRegexResp>, I>>(object: I): MemoiseDeleteByRegexResp {
    const message = createBaseMemoiseDeleteByRegexResp();
    message.numDeleted = object.numDeleted ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
