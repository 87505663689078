<ng-template #settingsMenu>
    <div class="settings">
        <div class="row">
            <div class="label">Log Scale</div>
            <switch-button
                [active]="logScale"
                (onToggle)="onToggleLogScale()"
                title="Toggles logarithmic y-scale"
            />
        </div>
        <div class="label">Whisker Display Mode</div>
        <multi-state-button
          [stateNames]="whiskerDisplayModes"
          [items]="whiskerDisplayModes"
          [activeState]="whiskerDisplayMode"
          (onChange)="onChangeWhiskerDisplayMode($event)">
        </multi-state-button>
        <div class="label">Zoom Columns To</div>
        <multi-state-button
          [stateNames]="zoomModes"
          [items]="zoomModes"
          [activeState]="zoomMode"
          (onChange)="onChangeZoomMode($event)">
        </multi-state-button>
    </div>
</ng-template>

<interactive-canvas [ngClass]="mdl.cursorShown" [drawer]="drawer" [drawNotifier]="mdl" [interactionHandler]="interactionHandler" [transform]="transform">
</interactive-canvas>